import { SceneItem, SceneItemArgs } from '@/classes/SceneController/abstract/SceneItem.class'
import {
  Color3,
  Mesh,
  MeshBuilder,
  StandardMaterial,
  Texture, Vector3
} from '@babylonjs/core'

export default class PersonModel extends SceneItem {
  private _mesh!: Mesh

  // constructor (args : SceneItemArgs) {
  //   super(args)
  // }

  public init (pictureUrl : string) : void {
    this._mesh = MeshBuilder.CreatePlane('plane', {
      size: 2
    }, this.scene)

    const material = new StandardMaterial('picture', this.scene)
    const texture = new Texture(pictureUrl, this.scene)
    texture.hasAlpha = true
    texture.vScale = -1
    texture.uScale = -1
    material.diffuseTexture = texture
    material.disableLighting = true
    material.useAlphaFromDiffuseTexture = true
    material.emissiveColor = Color3.White()

    this._mesh.material = material

    // this.scene.onBeforeRenderObservable.add(this._update)
  }

  // private _update = () : void => {
  //   if (this._mesh) {
  //     this._mesh.rotation.x += 0.01
  //   }
  // }

  public setPosition (position : Vector3) : void {
    this._mesh.position = position
    this._mesh.lookAt(new Vector3(0, 2, 0))
    this._mesh.rotation.x += Math.PI

    // console.log(this._mesh.rotation)
  }
}
