import SceneController from '@/classes/SceneController.class'
import {
  Color4, DefaultRenderingPipeline,
  DeviceOrientationCamera, HemisphericLight, Vector3, WebXRDefaultExperience
} from '@babylonjs/core'
import PersonModel from '@/components/DeviceOrientationChampions/classes/PersonModel.class'
import isMobile from 'is-mobile'
import ParticlesSceneItem
  from '@/components/DeviceOrientationChampions/classes/ParticlesBackground/ParticlesSceneItem.class'

interface ListOptions {
  count: number,
  radius: number,
  minRadiusPercents: number,
  path: string
}

export default class DeviceOrientationSceneController extends SceneController {
  private _camera! : DeviceOrientationCamera
  private _videoElement : HTMLVideoElement
  private _canvasElement: HTMLCanvasElement
  private _models : PersonModel[] = []

  private _particles!: ParticlesSceneItem
  private _xr!: WebXRDefaultExperience

  private _radiusPercents = 1
  private _xPosition = 0

  private _listsOptions: Map<string, ListOptions> = new Map<string, ListOptions>()
  private readonly _listId : string

  private get _options () : ListOptions {
    return this._listsOptions.get(this._listId)!
  }

  constructor ({ canvas, video, listId } : {canvas: HTMLCanvasElement, video: HTMLVideoElement, listId: string}) {
    super({ canvas })

    this._createLists()

    this._listId = listId
    this._videoElement = video
    this._canvasElement = canvas

    this._createOrientationCamera()
    this.scene.clearColor = new Color4(0, 0, 0, 0.2)

    // const environment: Nullable<EnvironmentHelper> = this.scene.createDefaultEnvironment({
    //   enableGroundShadow: true,
    //   groundYBias: 1.8
    // })

    // environment!.setMainColor(BABYLON.Color3.FromHexString("#74b9ff"));

    this.scene.createDefaultXRExperienceAsync({
      // floorMeshes: [environment.ground]
    }).then((xrExperience) => {
      this._xr = xrExperience
    })

    this._particles = new ParticlesSceneItem({
      sceneController: this
    })

    this._initVideo()
    this._createModels()
  }

  private _createLists () : void {
    this._listsOptions.set('holeinone', {
      // count: 16,
      // radius: 4,
      // minRadiusPercents: 0.63,
      count: 18,
      radius: 4.7,
      minRadiusPercents: 0.6,
      path: '/1/pics/'
    })

    this._listsOptions.set('club-champions', {
      count: 20,
      radius: 5,
      minRadiusPercents: 0.63,
      path: '/2/pics/'
    })

    this._listsOptions.set('club-captains', {
      count: 18,
      radius: 4.7,
      minRadiusPercents: 0.6,
      path: '/3/pics/'
    })
  }

  private _createOrientationCamera () : void {
    this._camera = new DeviceOrientationCamera('camera', Vector3.Zero(), this.scene)
    this._camera.position = new Vector3(0, 2, 0)
    this._camera.attachControl(this._canvasElement, true)

    const light = new HemisphericLight('test', new Vector3(1, 1, 1), this.scene)

    this._createPostprocess()
  }

  private _createPostprocess () : void {
    const defaultPipeline = new DefaultRenderingPipeline('default', true, this.scene, [this._camera])
    defaultPipeline.bloomEnabled = true
    defaultPipeline.fxaaEnabled = false
    defaultPipeline.bloomWeight = 0.2
    // defaultPipeline.cameraFov = this._camera.fov
  }

  private async _initVideo () {
    try {
      const localStream = await navigator.mediaDevices.getUserMedia({
        audio: false,
        video: isMobile() ? {
          facingMode: { exact: 'environment' }
        } : true
      })

      if (localStream) {
        this._videoElement.srcObject = localStream
        // this._videoElement.play()
        // console.log('ok2')
      }
    } catch (e) {
      this._videoElement.className = 'failed'
    }
  }

  private _createModels () : void {
    const count = this._options.count
    const radianStep = (Math.PI * 2) / count
    const picturesList : string[] = []
    // [
    //   '/pictures/1/front.png',
    //   '/pictures/1/right.png',
    //   '/pictures/1/back.png',
    //   '/pictures/1/left.png'
    // ]

    for (let i = 0; i < count; i++) {
      const personModel = new PersonModel({
        sceneController: this
      })

      const radius = (i === 0 ? (this._options.radius - 1) : this._options.radius) // * this._options.minRadiusPercents

      const position = new Vector3(
        Math.sin(radianStep * i) * radius,
        (i % 2 !== 0 ? 0.5 : -0.5) + 2,
        Math.cos(radianStep * i) * radius
      )

      const pictureUrl = picturesList.length > 0 ? picturesList[i] : `/pictures${this._options.path}${i + 1}.png`

      personModel.init(pictureUrl)
      personModel.setPosition(position)
      this._models.push(personModel)
    }
  }

  public updateZoom (touchesDistancePercents: number, xDelta: number) : void {
    this._radiusPercents += touchesDistancePercents
    this._xPosition -= xDelta * 0.003

    if (this._radiusPercents > 1) {
      this._radiusPercents = 1
    }

    if (this._radiusPercents < this._options.minRadiusPercents) {
      this._radiusPercents = this._options.minRadiusPercents
    }

    const count = this._options.count
    const radianStep = (Math.PI * 2) / count

    this._models.forEach((personModel, i) => {
      const radius = (i === 0 ? (this._options.radius - 1) : this._options.radius) * this._radiusPercents

      const position = new Vector3(
        Math.sin(radianStep * i + this._xPosition) * radius,
        (i % 2 !== 0 ? 0.5 : -0.5) + 2,
        Math.cos(radianStep * i + this._xPosition) * radius
      )

      personModel.setPosition(position)
    })
  }
}
