import { Color4, ParticleSystem, Texture, Vector3 } from '@babylonjs/core'
import { SceneItem, SceneItemArgs } from '@/classes/SceneController/abstract/SceneItem.class'

export default class ParticlesSceneItem extends SceneItem {
  private _particleSystem: ParticleSystem

  constructor (args: SceneItemArgs) {
    super(args)
    this._particleSystem = new ParticleSystem('particles', 3000, this.scene)
    this._particleSystem.particleTexture = new Texture('/textures/particle.png', this.scene)

    // this._particleSystem.emitter = boxEmitter
    this._particleSystem.createBoxEmitter(Vector3.Up(), Vector3.Up(), new Vector3(-5, -9, -5), new Vector3(5, 0, 5))
    this._particleSystem.minLifeTime = 10
    this._particleSystem.maxLifeTime = 60
    this._particleSystem.preWarmCycles = 0
    this._particleSystem.maxSize = 0.07
    this._particleSystem.minSize = 0.005
    this._particleSystem.updateSpeed = 0.02

    // this._particleSystem.addColorGradient(0, new Color4(0, 0, 0, 0))
    // this._particleSystem.addColorGradient(0.5, new Color4(1, 1, 1, 1))
    // this._particleSystem.addColorGradient(1, new Color4(0, 0, 0, 0))
    this._particleSystem.start()
  }
}
