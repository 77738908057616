import { Color3, Color4, Engine, Scene } from '@babylonjs/core'
import CameraController from './SceneController/CameraController.class'

class SceneController extends EventTarget {
  private _canvas: HTMLCanvasElement
  private _engine!: Engine
  private _scene!: Scene

  private _cameraController!: CameraController

  get cameraController () : CameraController {
    return this._cameraController
  }

  get engine () : Engine {
    return this._engine
  }

  get scene () : Scene {
    return this._scene
  }

  get canvas () : HTMLCanvasElement {
    return this._canvas
  }

  constructor ({ canvas }: { canvas: HTMLCanvasElement }) {
    super()
    this._canvas = canvas

    this._initEngine()

    window.addEventListener('resize', this._onWindowResize)
  }

  _onWindowResize = (): void => {
    setTimeout(() => {
      this._engine.resize()
    }, 0)
  }

  private _initEngine (): void {
    this._engine = new Engine(this._canvas, true)
    this._engine.setHardwareScalingLevel(0.8)
    this._scene = new Scene(this._engine)
    this._scene.ambientColor = Color3.White()
    this._scene.clearColor = new Color4(1, 1, 1, 1)

    // const hdrTexture: CubeTexture = CubeTexture.CreateFromPrefilteredData("/projects/about-immercyb/env/autoshop.env", this._scene)
    // this._scene.environmentTexture = hdrTexture

    this._engine.runRenderLoop(() => {
      this._scene.render()
    })
  }

  public createCameraController (): void {
    this._cameraController = new CameraController({
      sceneController: this
    })
  }

  public dispose () :void {
    window.removeEventListener('resize', this._onWindowResize)
    this._engine.dispose()
  }
}

export default SceneController
