
import { defineComponent, reactive } from 'vue'

export default defineComponent({
  setup (props, { emit }) {
    const state = reactive({
      visible: false
    })

    state.visible = window.DeviceOrientationEvent && !!DeviceOrientationEvent.requestPermission

    if (!state.visible) {
      emit('granted', true)
    }

    const onAllowClick = () : void => {
      if (DeviceOrientationEvent) {
        DeviceOrientationEvent.requestPermission().then((result) => {
          if (result === 'granted') {
            emit('granted', true)
          }
          state.visible = false
        }).catch((e) => {
          console.log(e)
        })
      }
    }

    const onCancelClick = () : void => {
      emit('granted', false)
      state.visible = false
    }

    return {
      state,
      onAllowClick,
      onCancelClick
    }
  }
})
