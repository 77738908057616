import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "device-orientation-champions" }
const _hoisted_2 = {
  key: 0,
  class: "video-wrapper"
}
const _hoisted_3 = {
  autoplay: "",
  muted: "",
  playsinline: "",
  ref: "videoEl"
}
const _hoisted_4 = { class: "canvas-wrapper" }
const _hoisted_5 = { ref: "canvasEl" }
const _hoisted_6 = {
  key: 1,
  class: "touches-handler",
  ref: "touchesHandlerEl"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_request_accelerometer = _resolveComponent("request-accelerometer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.accelometerGranted)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("video", _hoisted_3, null, 512)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("canvas", _hoisted_5, null, 512)
    ]),
    (_ctx.isMobileDevice)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, null, 512))
      : _createCommentVNode("", true),
    (_ctx.canvasEl)
      ? (_openBlock(), _createBlock(_component_request_accelerometer, {
          key: 2,
          onGranted: _ctx.onDeviceOrientationPermissionGranted
        }, null, 8, ["onGranted"]))
      : _createCommentVNode("", true)
  ]))
}