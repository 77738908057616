
import { defineComponent, ref, reactive, onMounted, onUnmounted } from 'vue'
import { useRoute } from 'vue-router'
import isMobile from 'is-mobile'
import { detect } from 'detect-browser'

export default defineComponent({
  name: 'ArGolfGirl',
  setup () {
    const browserInfo = detect()
    const fullScreenEnabled = ref<boolean>(false)
    const fullScreenAvailable = ref<boolean>(false)
    const isMobileDevice = ref(isMobile())
    const qrCodeVisible = ref(false)
    const reveal = ref('auto')
    const route = useRoute()

    const tooltipText = ref('Для перехода в дополненную реальность нажмите эту кнопку на экране мобильного телефона')

    if (browserInfo?.os?.toLocaleLowerCase() === 'ios' && browserInfo.name.toLocaleLowerCase() !== 'safari') {
      tooltipText.value = 'Для перехода в дополненную реальность нажмите эту кнопку в браузере Safari'
    } else if (browserInfo!.os!.toLocaleLowerCase().indexOf('android') >= 0 && browserInfo!.name! !== 'chrome') {
      tooltipText.value = 'Для перехода в дополненную реальность нажмите эту кнопку в браузере Chrome'
    }

    if (isMobile()) {
      if (browserInfo?.os === 'iOS' && window.navigator.userAgent.toLowerCase().indexOf('safari') < 0) {
        tooltipText.value = 'Для перехода в дополненную реальность нажмите эту кнопку в браузере Safari'
      } else if (window.navigator.userAgent.toLowerCase().indexOf('yasearch') >= 0 || window.navigator.userAgent.toLowerCase().indexOf('yabrowser') >= 0) {
        if (browserInfo?.os === 'iOS') {
          tooltipText.value = 'Для перехода в дополненную реальность нажмите эту кнопку в браузере Safari'
        } else {
          tooltipText.value = 'Для перехода в дополненную реальность нажмите эту кнопку в браузере Chrome'
        }
      } else if (window.navigator.userAgent.toLowerCase().indexOf('firefox') >= 0) {
        if (browserInfo?.os === 'iOS') {
          tooltipText.value = 'Для перехода в дополненную реальность нажмите эту кнопку в браузере Safari'
        } else {
          tooltipText.value = 'Для перехода в дополненную реальность нажмите эту кнопку в браузере Chrome'
        }
      } else if (window.navigator.userAgent.toLowerCase().indexOf('opr/') >= 0 || window.navigator.userAgent.toLowerCase().indexOf('opios/') >= 0) {
        if (browserInfo?.os === 'iOS') {
          tooltipText.value = 'Для перехода в дополненную реальность нажмите эту кнопку в браузере Safari'
        } else {
          tooltipText.value = 'Для перехода в дополненную реальность нажмите эту кнопку в браузере Chrome'
        }
      }
    }

    const tooltipVisible = ref(false)

    if (route.query.ar) {
      reveal.value = 'interaction'
    }

    onMounted(() => {
      fullScreenAvailable.value = document.fullscreenEnabled
      document.addEventListener('click', onDocumentClick)
      setTimeout(() => {
        tooltipVisible.value = true
      }, 3000)
    })

    onUnmounted(() => {
      document.removeEventListener('click', onDocumentClick)
    })

    const onDocumentClick = (e: MouseEvent) => {
      if (qrCodeVisible.value && (e.target as HTMLElement).className !== 'ar-button-desktop') {
        qrCodeVisible.value = false
      }
    }

    const onFullScreenChange = () => {
      fullScreenEnabled.value = !!document.fullscreenElement
      console.log('Full screen', fullScreenEnabled.value)
    }

    const onFullScreenButtonClick = async () => {
      const body = document.querySelector('body')
      if (body) {
        body.addEventListener('fullscreenchange', onFullScreenChange)
        await document.querySelector('body')?.requestFullscreen()
      }
    }

    const onDesktopArButtonClick = () => {
      if (!qrCodeVisible.value) {
        qrCodeVisible.value = true
      }
    }

    return {
      fullScreenAvailable,
      fullScreenEnabled,
      isMobileDevice,
      qrCodeVisible,
      tooltipVisible,
      tooltipText,
      reveal,
      onFullScreenButtonClick,
      onDesktopArButtonClick
    }
  }
})
