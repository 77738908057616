
import { useRoute } from 'vue-router'
import { defineComponent, ref, onUnmounted, onMounted, nextTick } from 'vue'
import DeviceOrientationSceneController
  from '@/components/DeviceOrientationChampions/classes/DeviceOrientationSceneController.class'
import RequestAccelerometer from '@/components/DeviceOrientationChampions/RequestAccelerometer.vue'
import isMobile from 'is-mobile'

export default defineComponent({
  components: {
    RequestAccelerometer
  },
  name: 'DeviceOrientationChampions',
  setup () {
    const videoEl = ref<HTMLVideoElement | null>(null)
    const canvasEl = ref<HTMLCanvasElement | null>(null)
    const touchesHandlerEl = ref<HTMLDivElement | null>(null)
    const isMobileDevice = ref<boolean>(isMobile())
    const accelometerGranted = ref(false)

    const route = useRoute()

    let touchesList : Touch[] = []
    let touchesInitialDistance = 0
    let touchesDistance = 0
    let touchesDistancePercents = 0
    let singleTouchXPosition = 0
    let singleToucheXDelta = 0

    let sceneController : DeviceOrientationSceneController | null = null

    const onDeviceOrientationPermissionGranted = () => {
      accelometerGranted.value = true
      nextTick(() => {
        sceneController = new DeviceOrientationSceneController({
          listId: route.name! as string,
          canvas: canvasEl.value as HTMLCanvasElement,
          video: videoEl.value as HTMLVideoElement
        })
      })
    }

    onMounted(() => {
      if (touchesHandlerEl.value) {
        const touchesHandler = touchesHandlerEl.value!
        touchesHandler.addEventListener('touchstart', onTouchStarted)
        touchesHandler.addEventListener('touchend', onTouchEnded)
        touchesHandler.addEventListener('touchmove', onTouchMoved)
      }
    })

    onUnmounted(() => {
      if (touchesHandlerEl.value) {
        const touchesHandler = touchesHandlerEl.value!
        touchesHandler.removeEventListener('touchstart', onTouchStarted)
        touchesHandler.removeEventListener('touchend', onTouchEnded)
        touchesHandler.removeEventListener('touchmove', onTouchMoved)
      }
      if (sceneController) sceneController.dispose()
    })

    const onTouchStarted = (e : TouchEvent) => {
      const touches : Touch[] = Array.from<Touch>(e.touches)

      touches.forEach((touch) => {
        if (touchesList.filter((touchItem) => touchItem.identifier === touch.identifier).length === 0) {
          touchesList.push(touch)
        }
      })

      if (touchesList.length >= 2) {
        const [t1, t2] = touchesList
        touchesInitialDistance = Math.sqrt(Math.pow(t1.pageX - t2.pageX, 2) + Math.pow(t1.pageY - t2.pageY, 2))
      }

      if (touchesList.length >= 1) {
        singleTouchXPosition = touchesList[0].pageX
      }
    }

    const onTouchEnded = (e: TouchEvent) => {
      const touches : Touch[] = Array.from<Touch>(e.touches)
      const newTouchesList : Touch[] = []

      touches.forEach((touch) => {
        if (touchesList.filter((touchItem) => touch.identifier === touchItem.identifier).length === 0) {
          newTouchesList.push(touch)
        }
      })

      touchesList = newTouchesList
    }

    const onTouchMoved = (e: TouchEvent) => {
      const touches : Touch[] = Array.from<Touch>(e.touches)
      touches.forEach((touch) => {
        touchesList.forEach((touchItem, index) => {
          if (touchItem.identifier === touch.identifier) {
            touchesList[index] = touch
          }
        })
      })

      if (touchesList.length >= 2) {
        const [t1, t2] = touchesList
        touchesDistance = Math.sqrt(Math.pow(t1.pageX - t2.pageX, 2) + Math.pow(t1.pageY - t2.pageY, 2))
        updateTouchesPercents()
      }

      if (touchesList.length === 1) {
        singleToucheXDelta = singleTouchXPosition - touchesList[0].pageX
        singleTouchXPosition = touches[0].pageX
        updateTouchesPercents()
      }
    }

    const updateTouchesPercents = () => {
      touchesDistancePercents = touchesDistance > 0 ? touchesInitialDistance / touchesDistance - 1 : 0
      touchesInitialDistance = touchesDistance
      if (sceneController) sceneController.updateZoom(touchesDistancePercents, singleToucheXDelta)
    }

    return {
      videoEl,
      canvasEl,
      touchesHandlerEl,
      isMobileDevice,
      accelometerGranted,
      onDeviceOrientationPermissionGranted
    }
  }
})
