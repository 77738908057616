import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import ArGoflGirl from '@/components/ArGoflGirl.vue'
// import Champions from '@/components/Champions.vue'
import DeviceOrientationChampions from '@/components/DeviceOrientationChampions.vue'

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    name: 'Home',
    component: ArGoflGirl
  },
  {
    path: '/holeinone',
    name: 'holeinone',
    component: DeviceOrientationChampions
  },
  {
    path: '/club-champions',
    name: 'club-champions',
    component: DeviceOrientationChampions
  },
  {
    path: '/club-captains',
    name: 'club-captains',
    component: DeviceOrientationChampions
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
